import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Connect, TrustedUser, StrategyPlay } from '@carbon/pictograms-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const PageDescription = makeShortcode("PageDescription");
const ExpressiveList = makeShortcode("ExpressiveList");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
  <Column colMd={7} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABgUlEQVQoz7VTy44BURBtYoOQWJAI3dr7Ld10YmMjNkLojpBIRKzaDhsSJDbs+Ao/4CP6B3yGzzhSlYwxY2YxM5nFuVXVt+rk3HP7CoIg4DNsNtuP8TT/SvgnOBwOBAIBeL1eOJ1OBINByLKMaDT6ZYzFYpxTJMTjcQZxMKHL5UKv14OiKPD7/dxcKpWQSqWQz+eRTqc5ZjIZFAoFZLNZBtW0l0wmoaoqz304Milbr9cYjUbodDqYTCZoNpvYbDbI5XIwDAO1Wg3z+RzdbpdFEKbTKdxu98P7B6HP58Nut8PxeMRsNsP1esVyucTlcsHhcIBlWTBNE9vtFqfTCefzmfdutxvC4fArod1uR7vdxmAwQL/fx2KxYLX7/R7D4RCr1QqtVgv1eh3j8ZhrXddZgMfjeSekpVgsolKpsI/kR7VaZR/L5TLXlJN/lFMPgWYajQb3UE0XxuKIMBQKIRKJQJIkBh2BoiiKjLfvzznNEKmmaUgkEnw5//Mf/vZlfPNKcAdlwzPAGM3zVgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ee93da937363ea9263de1098d0eed4c0/b5e35/tekDatum_canva_large.webp 288w", "/static/ee93da937363ea9263de1098d0eed4c0/0bf43/tekDatum_canva_large.webp 576w", "/static/ee93da937363ea9263de1098d0eed4c0/73abe/tekDatum_canva_large.webp 1152w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ee93da937363ea9263de1098d0eed4c0/0b458/tekDatum_canva_large.png 288w", "/static/ee93da937363ea9263de1098d0eed4c0/91cfa/tekDatum_canva_large.png 576w", "/static/ee93da937363ea9263de1098d0eed4c0/26cda/tekDatum_canva_large.png 1152w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ee93da937363ea9263de1098d0eed4c0/26cda/tekDatum_canva_large.png",
              "alt": "AI QA TekDatum",
              "title": "AI QA TekDatum",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
  </Column>
    </Row>
    <PageDescription mdxType="PageDescription">
      <p>{`Merging AI, Project Management, and QA Practices`}</p>
    </PageDescription>
    <p>{`TekDatum team is based in Houston, Chicago, and Costa Rica, with a wide range of skills and experience across several industries. We have vast experience developing and delivering Quality A.I. and Data Science related projects for multiple verticals.
Companies rely on our high-performance, distributed Agile teams to enhance their existing resources and accelerate their time to success with cutting-edge technologies and guaranteeing high-quality standards.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We are passionate technologists with a focus on people, processes, Quality, and innovation.`}</p>
    </blockquote>
    <h2>{`Principles`}</h2>
    <div className="expressive-content-list-group">
  <ExpressiveList title="Quality Mindset Team" background={true} pictogram={<TrustedUser className="my-custom-class" mdxType="TrustedUser" />} mdxType="ExpressiveList">
  We believe in growth through Quality. We are a team of people who are dedicated to ensuring that the Quality of our outcomes is superior, and proving it for ourselves as well as for our customers, who expect nothing less than the best.  
  </ExpressiveList>
  <ExpressiveList title="Data Science Passionate Experts" background={true} pictogram={<Connect className="my-custom-class" mdxType="Connect" />} mdxType="ExpressiveList">
  We believe that a quality-oriented approach to design is essential to the product's success, and that is why we employ it  along with an explainable A.I. informed drivetrain approach.  </ExpressiveList>
    <ExpressiveList title="Business-driven Solutions" background={true} pictogram={<StrategyPlay className="my-custom-class" mdxType="StrategyPlay" />} mdxType="ExpressiveList">
        <p>{`  `}<strong parentName="p">{`We are different.`}</strong>{` Our clients value the fact that we get to know their business and work with them in the most effective way possible, developing solutions that truly meet their needs. Our people are passionate about what they do and help customers overcome challenges with an approachable, collaborative approach`}</p>
  </ExpressiveList>
    </div>
    <h2>{`Our trusted partnerships`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="Triunity Partners Group" title="Manages digital transformations and  technology commercialization journeys in the public and private sectors through a network of delivery partners." color="dark" href="https://triunity.info/" actionIcon="arrowRight" className="articleCard-AboutUs" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2S6UUYX/xAAaEAACAgMAAAAAAAAAAAAAAAACEQABEiAh/9oACAEBAAEFAmbyKV3T/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEgITL/2gAIAQEABj8CdGY//8QAHBABAAEEAwAAAAAAAAAAAAAAAQARICExQWHw/9oACAEBAAE/IeHIa7nqxoyUs//aAAwDAQACAAMAAAAQbA//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQABBAMAAAAAAAAAAAAAAAERACBRcSExQf/aAAgBAQABPxAAXSvbeKhkWPkcKlM/Fn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a67994824ca2dff0fbb787503e64b6d7/b5e35/Triunity.webp 288w", "/static/a67994824ca2dff0fbb787503e64b6d7/d37ea/Triunity.webp 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a67994824ca2dff0fbb787503e64b6d7/2fc17/Triunity.jpg 288w", "/static/a67994824ca2dff0fbb787503e64b6d7/49084/Triunity.jpg 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a67994824ca2dff0fbb787503e64b6d7/49084/Triunity.jpg",
                "alt": "Triunity",
                "title": "Triunity",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="Pernix" title="Cofounded by former Intel and HP employees, focused on building reliable, scalable and secure software." color="dark" href="https://www.pernix-solutions.com/" actionIcon="arrowRight" className="articleCard-AboutUs" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7NdCCVf/xAAXEAEAAwAAAAAAAAAAAAAAAAARAhIg/9oACAEBAAEFAprXP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAgMmH/2gAIAQEABj8Cwtf/xAAaEAEAAgMBAAAAAAAAAAAAAAABECEAETFx/9oACAEBAAE/IaGqwJKS+ycI/9oADAMBAAIAAwAAABDrD//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxBXZ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EAn/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMRBBUYHw/9oACAEBAAE/EHkmilOlwP3mVYo7c/ZDUQREsm93XH//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12903cc94b6b8cce99cc2120e1fcc058/b5e35/pernix.webp 288w", "/static/12903cc94b6b8cce99cc2120e1fcc058/d37ea/pernix.webp 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12903cc94b6b8cce99cc2120e1fcc058/2fc17/pernix.jpg 288w", "/static/12903cc94b6b8cce99cc2120e1fcc058/49084/pernix.jpg 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/12903cc94b6b8cce99cc2120e1fcc058/49084/pernix.jpg",
                "alt": "Pernix",
                "title": "Pernix",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      